.postItem {
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #f8f9fa;
    width: 92.5%; 
    margin-bottom: 10px; 
    margin-left: 1%;
    padding: 1% 0% 2% 3.25%;
    list-style: none;
    word-wrap: break-word;
    overflow-wrap: break-word;
    overflow-x: hidden;
    box-sizing: border-box;

}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
  }
  