
.main-div h1{
  background-color: rgb(209, 231, 248);
  text-align: center;
  margin:0px;
}
.dashboard {
    display: flex;
    flex-direction: row;
    align-items:center;
    margin: 0 2%;
    padding-top: 5%;
    height: 70vh; /* Adjust height as needed */
  }
.main-div{
    background: rgba(238, 173, 173, 0.5);
    height: 100vh;
    margin: 0px;
    /* padding-top: 20%; */
  }