/* .App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  } */
  
  .page{
    display:flex;
    flex-direction: column;
    background-color: yellow;
    width:100vw;
    height: 100vh;
    margin: auto
  }
  
  .login-page{
    display:flex;
    flex-direction: column;
    align-items: center;
    background-color: red;
    width: 30vw;
    height: 80vh;
    margin: auto 10%;
  
  }
  .get-details{
    display: flex;
    flex-direction: column;
    /* margin-top: 10vh ; */
    align-content: center;
    justify-content: center;
    margin-top: 30%;

  }
  .get-details input{
    margin: 8% auto ;
    width: 100%;
    height: 5.5vh;


  }

  .login-submit{
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  .login-submit button{
    width: 100%;
    height: 5vh;
    margin: 10% auto;
  }

  .sign-up-submit{
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
  }

  .sign-up-submit button{
    width: 70%;
    height: 5vh;
    margin: 4% auto;
  }